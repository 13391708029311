* {
  margin: 0;
  padding: 0;
  box-sizing: content-box;
}

body {
  margin: 0;
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  overflow: scroll;
  overflow-x: hidden;
}
::-webkit-scrollbar {
  width: 0;
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: transparent;
}

/* color: transparent;
background: linear-gradient(90deg, #fa541c 20%, #8e33ff 100%);
background-clip: text; */
